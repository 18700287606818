import React from "react"
import { injectIntl, Link, } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const SecondPage = ({ intl }) => (
	<Layout pageName="second-page">
		<SEO
			lang={intl.locale}
			title={intl.formatMessage({ id: "title" })}
			description={intl.formatMessage({ id: "description" })}
			keywords={[`gatsby`, `application`, `react`]}
		/>
		<section className="wrapper">
			<h2>Page 2</h2>
		</section>
	</Layout>
)

export default injectIntl(SecondPage)
